import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { defaultResponseParser, useApi } from '../../../hooks/useApi';
import { getRequest, postRequest, putRequest } from '../../../api';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { paths } from '../../../constants/paths';
import { getRolesListUrl, getUsersObjects, getUserUrl, resetPasswordUrl, updateUserUrl } from '../../../constants/api';
import ButtonLink from '../../../components/ui/buttonLink';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import { IUser, IUserAvailableObject } from '../../../typings/users';
import { IEditUser } from './types';
import {
  EFieldTypes,
  IInputValue,
  InputStatus,
  InputType,
  defaultNotRequiredValue,
  defaultRequiredValue,
} from '../../../components/ui/input/types';
import Input from '../../../components/ui/input';
import MaskInput from '../../../components/ui/input/maskInput';
import { IApiError, IApiResponse } from '../../../typings/api';
import { IRole } from '../../../typings/roles';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import SelectMultiple from '../../../components/ui/selectMultiple';
import { ISelectMultipleOption } from '../../../components/ui/selectMultiple/types';
import SimpleTabs from '../../../components/ui/simpleTabs';
import { ISimpleTab } from '../../../components/ui/simpleTabs/types';
import TabNavButtons from '../../../components/tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../../components/tabs/tabNavButtons/utils';
import { EMAIL_REGEX } from '../../../constants/regex';
import Message from '../../../components/message';
import StatusSelect from '../../../components/statusSelect';
import { Status } from '../../../typings/status';
import { StatusIconColor } from '../../../components/statusSelect/types';
import { dateFormatNoTime } from '../../../constants/date';
import UniversalModal from '../../../components/ui/universalModal';
import { IConfirmData } from '../../../components/ui/universalModal/types';
import { chancelChangesModal, defaultConfirm, saveChangesModal } from '../../../components/ui/universalModal/config';
import { ButtonType } from '../../../components/ui/button/types';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import TabsCustom from '../../../components/tabsCustom';
import { ICustomTab } from '../../../components/tabsCustom/types';
import { selectAllOptionKey } from '../../../constants/select';

const defaultUserData: IEditUser = {
  comment: { ...defaultNotRequiredValue },
  phone: { ...defaultNotRequiredValue },
  firstName: { ...defaultRequiredValue },
  lastName: { ...defaultRequiredValue },
  middleName: { ...defaultNotRequiredValue },
  email: { ...defaultRequiredValue },
};

const UserPage: FC = () => {
  const dispatch = useAppDispatch();

  const usersPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.users));

  const [currentUserData, setCurrentUserData] = useState<IEditUser>({ ...defaultUserData });

  const { data: userData, sendRequest: getUser, loading: userLoading } = useApi<IUser>(getRequest);

  const { data: roles, sendRequest: getRoles, loading: rolesLoading } = useApi<IApiResponse<IRole>>(postRequest);

  const { data: objects, sendRequest: getObjects } = useApi<IUserAvailableObject[]>(getRequest);

  const { sendRequest: updateUser, loading: updateUserLoading } = useApi(putRequest, defaultResponseParser, false);

  const { sendRequest: createUser, loading: createUserLoading } = useApi(postRequest, defaultResponseParser, false);

  const { sendRequest: resetPassword, loading: resetPasswordLoading } = useApi(postRequest);

  const [status, setStatus] = useState<Status>(Status.active);

  const [userRoles, setUserRoles] = useState<{
    value: string[];
    status: InputStatus;
    errorText?: string;
  }>({
    value: [],
    status: InputStatus.normal,
    errorText: '',
  });

  const [userObjects, setUserObjects] = useState<{
    value: string[];
    status: InputStatus;
    errorText?: string;
  }>({
    value: [],
    status: InputStatus.normal,
    errorText: '',
  });

  const navigate = useNavigate();

  const params = useParams();

  const [isCreate, setIsCreate] = useState(false);

  const [title, setTitle] = useState('');

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const getUserData = useCallback(
    async (id = userData?.id || '') => {
      await getUser(getUserUrl(id));
    },
    [getUser, userData?.id]
  );

  useEffect(() => {
    if (params.userId === 'create') {
      setIsCreate(true);
      setCurrentUserData(JSON.parse(JSON.stringify({ ...defaultUserData })));
    } else {
      setIsCreate(false);
      getUserData(params.userId);
    }
    getRoles(getRolesListUrl(), {
      page: 0,
      count: 0,
      search: '',
    });
  }, [params.userId]);

  useEffect(() => {
    getObjects(getUsersObjects(), {
      params: {
        companyId: userData?.companyId || '',
        userId: params.userId === 'create' ? '' : params.userId,
      },
    });
  }, [params.userId, userData?.companyId]);

  useEffect(() => {
    if (userData) {
      setCurrentUserData({
        comment: { ...defaultNotRequiredValue, value: userData.comment },
        phone: { ...defaultNotRequiredValue, value: userData.phone },
        firstName: { ...defaultRequiredValue, value: userData.firstName },
        lastName: { ...defaultRequiredValue, value: userData.lastName },
        middleName: { ...defaultNotRequiredValue, value: userData.middleName },
        email: { ...defaultRequiredValue, value: userData.email },
      });
      if (!isCreate) {
        setStatus(userData.isActive ? Status.active : Status.inactive);
      }
      setUserRoles({
        errorText: '',
        status: InputStatus.normal,
        value: userData.roles.map((item) => item.id),
      });
      setUserObjects({
        errorText: '',
        status: InputStatus.normal,
        value:
          userData.availableObjects === null
            ? [selectAllOptionKey]
            : userData.availableObjects.map((item) => item.objectId),
      });
    }
  }, [userData]);

  useEffect(() => {
    const newTitle = isCreate
      ? 'Создание пользователя'
      : userData?.fio ||
        `${currentUserData?.lastName.value || ''} ${currentUserData?.firstName.value || ''} ${
          currentUserData?.middleName.value || ''
        }`;
    setTitle(newTitle);
    dispatch(setHeaderTitle(newTitle));
  }, [isCreate, currentUserData]);

  const validateInfo = useCallback((): boolean => {
    let isError = false;

    const newCurrentUserData = { ...currentUserData };

    Object.keys(newCurrentUserData).forEach((name) => {
      const field = newCurrentUserData[name as keyof IEditUser] as IInputValue;
      if (field.isRequired) {
        if (
          (name === 'phone' && !(isValidPhoneNumber(field.value) || isValidPhoneNumber(`+${field.value}`))) ||
          (name === 'email' && !EMAIL_REGEX.test(field.value))
        ) {
          isError = true;
          field.errorText = 'Поле заполнено некорректно';
          field.status = InputStatus.error;
        } else if (!field.value || !field.value.trim()) {
          isError = true;
          field.errorText = 'Поле обязательно для заполнения';
          field.status = InputStatus.error;
        }
      }
    });

    if (userRoles.value.length === 0) {
      isError = true;
      userRoles.errorText = 'Выберите роль пользователя';
      userRoles.status = InputStatus.error;
    }

    if (userObjects.value.length === 0) {
      isError = true;
      userObjects.errorText = 'Выберите объекты пользователя';
      userObjects.status = InputStatus.error;
    }

    setUserObjects(userObjects);
    setCurrentUserData(newCurrentUserData);

    return isError;
  }, [currentUserData, userObjects, userRoles]);

  const rolesTabs = useMemo(
    () =>
      userRoles.value.map((item) => {
        const element = roles?.items.find((role) => role.id === item);
        return {
          id: element?.id,
          name: element?.name,
        } as ISimpleTab;
      }),
    [roles?.items, userRoles.value]
  );

  const onSave = useCallback(async (): Promise<boolean> => {
    if (!validateInfo()) {
      const saveObject: any = {
        comment: currentUserData.comment.value.trim() ? currentUserData.comment.value : '',
        phone: currentUserData.phone.value,
        roles: rolesTabs,
        firstName: currentUserData.firstName.value,
        lastName: currentUserData.lastName.value,
        middleName: currentUserData.middleName.value.trim() ? currentUserData.middleName.value : '',
        email: currentUserData.email.value,
        isActive: status === Status.active,
        availableObjects:
          userObjects.value[0] === selectAllOptionKey
            ? null
            : objects?.filter((item) => userObjects.value.find((obj) => obj === item.objectId)),
      };

      if (!isCreate) {
        saveObject.id = userData?.id;
      }

      let resError = null;

      if (isCreate) {
        resError = await createUser(updateUserUrl(), saveObject);
      } else {
        resError = await updateUser(updateUserUrl(), saveObject);
      }
      const error = resError?.response?.data as IApiError;

      if (error) {
        if (error.message) {
          setConfirmData({
            isOpen: true,
            description: error.message,
            buttons: [
              {
                label: 'Понятно',
                type: ButtonType.primary,
                onClick: closeConfirm,
              },
            ],
          });
        } else {
          if (error.errorMessage === 'User exists with same username') {
            setCurrentUserData({
              ...currentUserData,
              email: {
                ...currentUserData.email,
                status: InputStatus.error,
                errorText: 'E-mail должен быть уникальным',
              },
            });
          }
          Message.error({
            content: isCreate ? 'Не удалось создать пользователя' : 'Не удалось обновить пользователя',
          });
        }
      } else {
        const fullName = `${saveObject.lastName} ${saveObject.firstName} ${saveObject.middleName}`;
        Message.success({
          content: isCreate ? `Новый пользователь ${fullName} создан` : `Данные пользователя ${fullName} обновлены`,
        });
        setWasChange(false);
        navigate(paths.users);
        return true;
      }
    }
    return false;
  }, [
    validateInfo,
    currentUserData.comment.value,
    currentUserData.phone.value,
    currentUserData.firstName.value,
    currentUserData.lastName.value,
    currentUserData.middleName.value,
    currentUserData.email.value,
    rolesTabs,
    status,
    objects,
    isCreate,
    userObjects.value,
    userData?.id,
    createUser,
    updateUser,
    closeConfirm,
    setWasChange,
    navigate,
  ]);

  const checkChanges = useCallback(
    (callBack = () => {}) => {
      if (wasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              const result = await onSave();
              if (result) {
                callBack();
              }
            },
            () => {
              callBack();
              closeConfirm();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, onSave, wasChange]
  );

  const goBack = useCallback(() => {
    checkChanges(() => navigate(paths.users));
  }, [checkChanges, navigate]);

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setWasChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const onChange = useCallback(
    (field: string) => (e: string | number) => {
      if (!wasChange) {
        setWasChange(true);
      }
      const newUserData = { ...currentUserData };
      const userField = newUserData[field as keyof IEditUser] as IInputValue;
      userField.value = e.toString();
      userField.status = InputStatus.normal;
      userField.errorText = '';
      setCurrentUserData(newUserData);
    },
    [currentUserData, setWasChange, wasChange]
  );

  const onChancel = useCallback(() => {
    setWasChange(false);
    if (isCreate) {
      navigate(paths.users);
    } else {
      getUserData();
    }
  }, [getUserData, isCreate, navigate, setWasChange]);

  const onTryChancel = useCallback(() => {
    if (wasChange) {
      setConfirmData(
        chancelChangesModal(
          () => {
            closeConfirm();
            onChancel();
          },
          () => {
            closeConfirm();
          }
        )
      );
    } else {
      onChancel();
    }
  }, [closeConfirm, onChancel, wasChange]);

  const onChangeRoles = useCallback(
    (value: string[]) => {
      setUserRoles({
        errorText: '',
        status: InputStatus.normal,
        value,
      });
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [setWasChange, wasChange]
  );

  const onChangeObjects = useCallback(
    (value: string[]) => {
      setUserObjects({
        errorText: '',
        status: InputStatus.normal,
        value,
      });
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [setWasChange, wasChange]
  );

  const onDeleteRole = useCallback(
    (id: string) => {
      setUserRoles({
        errorText: '',
        status: InputStatus.normal,
        value: userRoles.value.filter((item) => item !== id),
      });
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [setWasChange, userRoles.value, wasChange]
  );

  const onChangeStatus = useCallback(
    (value: Status | string) => {
      if (!wasChange) {
        setWasChange(true);
      }
      setStatus(value as Status);
    },
    [setWasChange, wasChange]
  );

  const onResetPassword = useCallback(async () => {
    const resError = await resetPassword(resetPasswordUrl(), { email: currentUserData.email.value });
    if (!resError?.response?.data) {
      Message.success({
        content: 'Приглашние отправлено',
      });
    }
  }, [currentUserData, resetPassword]);

  const onDeleteObject = useCallback(
    (id: string) => {
      if (!wasChange) {
        setWasChange(true);
      }
      if (userObjects.value[0] === selectAllOptionKey && objects) {
        setUserObjects({
          ...userObjects,
          value: objects.filter((item) => item.objectId !== id).map((item) => item.objectId),
        });
      } else {
        setUserObjects({ ...userObjects, value: userObjects.value.filter((item) => item !== id) });
      }
    },
    [objects, setWasChange, userObjects, wasChange]
  );

  const rolesOptions = useMemo(
    () =>
      roles?.items.map<ISelectMultipleOption>((action) => ({
        value: action.id || '',
        title: action.name || '',
      })),
    [roles]
  );

  const objectsOptions = useMemo(
    () => [
      { value: selectAllOptionKey, title: 'Все' },
      ...(objects?.map<ISelectMultipleOption>((object) => ({
        value: object.objectId || '',
        title: object.objectName || '',
      })) || []),
    ],
    [objects]
  );

  const tabs = useMemo(
    () =>
      userObjects.value[0] === selectAllOptionKey
        ? objects || []
        : userObjects.value?.map((item) => objects?.find((object) => object.objectId === item)),
    [objects, userObjects.value]
  );

  if (userLoading || rolesLoading || updateUserLoading || createUserLoading || resetPasswordLoading) {
    return (
      <div className="system-item__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return (
    <>
      <CommonHead seo={{ title }} />
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="user-page">
        <div className="user-page__back-button-wrapper">
          <ButtonLink withCallback onClick={goBack} leftIcon={<ArrowBackIcon />} content="Список пользователей" />
        </div>
        <div className="user-page__title">Персональные данные</div>
        <div className="user-page__inputs-container">
          <div className="user-page__inputs-row">
            <Input
              isDisabledStyle
              placeholder="Введите фамилию"
              title="Фамилия"
              disabled={!usersPermissions?.edit}
              status={currentUserData.lastName.status}
              errorText={currentUserData.lastName.errorText}
              isRequired
              value={currentUserData.lastName.value}
              onChange={onChange('lastName')}
              maxLength={80}
            />
            <Input
              isDisabledStyle
              placeholder="Введите имя"
              title="Имя"
              disabled={!usersPermissions?.edit}
              status={currentUserData.firstName.status}
              errorText={currentUserData.firstName.errorText}
              isRequired
              value={currentUserData.firstName.value}
              onChange={onChange('firstName')}
              maxLength={80}
            />
          </div>
          <div className="user-page__inputs-row">
            <Input
              isDisabledStyle
              placeholder="Введите отчество"
              title="Отчество"
              disabled={!usersPermissions?.edit}
              status={currentUserData.middleName.status}
              errorText={currentUserData.middleName.errorText}
              value={currentUserData.middleName.value}
              onChange={onChange('middleName')}
              maxLength={80}
            />
            <MaskInput
              isDisabledStyle
              onChange={onChange('phone')}
              title="Телефон"
              placeholder="+7 (123) 456 78 89"
              disabled={!usersPermissions?.edit}
              status={currentUserData.phone.status}
              errorText={currentUserData.phone.errorText}
              value={currentUserData.phone.value}
            />
          </div>
          <div className="user-page__inputs-row">
            <Input
              isDisabledStyle
              placeholder="yourname@mail.com"
              title="E-mail"
              disabled={!usersPermissions?.edit || !isCreate}
              status={currentUserData.email.status}
              errorText={currentUserData.email.errorText}
              isRequired
              value={currentUserData.email.value}
              onChange={onChange('email')}
              inputType={InputType.email}
              maxLength={100}
            />
            {!isCreate && (
              <StatusSelect
                title="Статус"
                value={status as Status}
                onChange={onChangeStatus}
                disabled={!usersPermissions?.edit}
                isDisabledStyle
                options={[
                  { value: Status.active, title: 'Активен', color: StatusIconColor.green },
                  { value: Status.inactive, title: 'Неактивен', color: StatusIconColor.red },
                ]}
              />
            )}
          </div>
          <div className="user-page__inputs-row">
            <Input
              isDisabledStyle
              title="Комментарий"
              value={currentUserData.comment.value}
              status={currentUserData.comment.status}
              onChange={onChange('comment')}
              disabled={!usersPermissions?.edit}
              placeholder="Введите комментарий"
              autoSize={{ minRows: isCreate ? 3 : 6, maxRows: isCreate ? 3 : 6 }}
              maxLength={200}
              fieldType={EFieldTypes.textArea}
            />
            {userData && !isCreate && (
              <div className="user-page__info">
                <div className="user-page__info-title">Дополнительная информация</div>
                <div className="user-page__info-content">
                  <div className="user-page__info-item">
                    <div className="user-page__info-item-label">Автор</div>
                    <div className="user-page__info-value">{userData?.createrName || '-'}</div>
                  </div>
                  <div className="user-page__info-item">
                    <div className="user-page__info-item-label">Дата создания</div>
                    <div className="user-page__info-value">
                      {userData.updateDate ? moment(userData.createDate).format(dateFormatNoTime) : '-'}
                    </div>
                  </div>
                  <div className="user-page__info-item">
                    <div className="user-page__info-item-label">Автор изменения</div>
                    <div className="user-page__info-value">{userData?.updaterName || '-'}</div>
                  </div>
                  <div className="user-page__info-item">
                    <div className="user-page__info-item-label">Дата изменения</div>
                    <div className="user-page__info-value">
                      {userData.updateDate ? moment(userData.updateDate).format(dateFormatNoTime) : '-'}
                    </div>
                  </div>
                  <div className="user-page__info-item">
                    <div className="user-page__info-item-label">Статус приглашения</div>
                    <div className="user-page__info-item-value">
                      {userData.inviteStatus ? 'Принял приглашение' : 'Не принял приглашение'}
                      {!userData.inviteStatus && usersPermissions?.edit && (
                        <div className="user-page__info-item-invite" role="presentation" onClick={onResetPassword}>
                          Пригласить повторно
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="user-page__roles">
          <div className="user-page__roles-select">
            <SelectMultiple
              notAllowAllChoice
              title="Роли"
              isRequired
              values={userRoles.value}
              onChange={onChangeRoles}
              disabled={!usersPermissions?.edit}
              options={rolesOptions}
              status={userRoles.status}
              errorText={userRoles.errorText}
            />
          </div>
          <SimpleTabs tabs={rolesTabs} onDelete={onDeleteRole} disabled={!usersPermissions?.edit} />
        </div>

        <div className="user-page__roles">
          <div className="user-page__roles-select">
            <SelectMultiple
              isSelectedAllDefault={userObjects.value[0] === selectAllOptionKey}
              title="Объекты пользователя"
              isRequired
              values={userObjects.value}
              onChange={onChangeObjects}
              disabled={!usersPermissions?.edit}
              options={objectsOptions}
              status={userObjects.status}
              errorText={userObjects.errorText}
              allSelectOptionValue={selectAllOptionKey}
            />
          </div>
          {!!tabs.length && (
            <TabsCustom
              showCloseIcon={usersPermissions?.edit}
              activeTabKey=""
              onDelete={onDeleteObject}
              tabs={tabs.map(
                (item, index) =>
                  ({
                    tabId: item?.objectId || index,
                    data: [
                      { fieldTitle: 'Объект', fieldData: item?.objectName },
                      { fieldTitle: 'Адрес', fieldData: item?.address },
                    ],
                  } as ICustomTab)
              )}
            />
          )}
        </div>

        <TabNavButtons
          buttons={tabNavButtonsDefault(
            {
              isHidden: !usersPermissions?.edit,
              text: 'Отмена',
              callBack: onTryChancel,
              disabled: !wasChange,
            },
            {
              isHidden: !usersPermissions?.edit,
              type: ButtonType.primary,
              text: isCreate ? 'Создать' : 'Сохранить',
              callBack: onSave,
              disabled: !wasChange,
            },
            { isHidden: true }
          )}
        />
      </div>
    </>
  );
};

export default UserPage;
