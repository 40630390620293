import { IApiExtendSetting } from '../../typings/api';
import { ISubscriber } from '../../typings/subscribers';
import { IAccessPointLogApiParams } from './accessPointLog';

export const SUBSCRIBERS_COUNT = 10;

export const subscribersInitApiSettings: IApiExtendSetting<ISubscriber> = {
  search: '',
  filters: [],
  sort: '',
  sortFields: [],
  page: 0,
  count: SUBSCRIBERS_COUNT,
};

export const getSubscribersInitApiSettings = (defaultSortField: string): IApiExtendSetting<ISubscriber> => ({
  sortFields: defaultSortField
    ? [
        {
          field: defaultSortField as keyof ISubscriber,
          descending: false,
        },
      ]
    : [],
  search: '',
  filters: [],
  sort: '',
  page: 0,
  count: SUBSCRIBERS_COUNT,
});

export const subscriberDeviceLogInitApiParams: IAccessPointLogApiParams = {
  count: 10,
  page: 0,
  dateFrom: '',
  dateTo: '',
};
