import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { toNumber } from 'lodash';
import { editCallCode } from '../../../../../api/callCode';
import { ICallCodeErrorType } from '../../../../../api/callCode/types';
import ChevronLeft from '../../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../../assets/svg/icons/chevronRight';
import { editCallCodeOneFlatUrl } from '../../../../../constants/api';
import { useApi } from '../../../../../hooks/useApi';
import Input from '../../../../ui/input';
import { InputStatus, InputType } from '../../../../ui/input/types';
import Loader from '../../../../ui/loader';
import { ELoaderColor } from '../../../../ui/loader/types';
import NotificationModal from '../../../../ui/notificationModal';
import { ICallCodesCheckTable } from './types';
import { CallCodesTableEditableColumns } from '../../callCodesTable/types';
import { IInvalidCode } from '../types';
import { ICallCode } from '../../../../../typings/systems/callCode';

const CallCodesCheckTable: FC<ICallCodesCheckTable> = (props) => {
  const {
    callCodes = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    systemId = '',
    onEditRow = () => {},
    handleOnChangeCode = () => {},
    prefix = 0,
    editRow = null,
    isFiveThousandth,
  } = props;

  const { sendRequest } = useApi(editCallCode);
  const [editingRecordIndex, setEditingRecordIndex] = useState<number | null>(null);
  const [clickNumber, setClickNumber] = useState<number>(0);
  const [notificationFlatModalIsOpen, setNotificationFlatModalIsOpen] = useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<IInvalidCode | null>(null);

  const handleOnClickCancelInModal = useCallback(() => {
    if (editRow && editingRecordIndex !== null) {
      handleOnChangeCode(editingRecordIndex, { ...editRow });
    }
    setEditingRecordIndex(null);
    setEditingRecord(null);
    onEditRow(null);
    setClickNumber(0);
    setNotificationFlatModalIsOpen(false);
  }, [editRow, editingRecordIndex, handleOnChangeCode, onEditRow]);

  const handleOnClickOkInModal = useCallback(() => {
    setNotificationFlatModalIsOpen(false);
  }, []);

  const handleOnChangeEditingRecord = useCallback(
    (value: string, fieldName: CallCodesTableEditableColumns) => {
      if (editingRecordIndex || editingRecordIndex === 0) {
        const updatedCallCode = { ...callCodes[editingRecordIndex] };
        updatedCallCode[fieldName] = value;

        if (fieldName === CallCodesTableEditableColumns.inBuildingCallCode) {
          updatedCallCode.inBuildingCallCodeIsValid = true;
          updatedCallCode.inBuildingCallCodeMessage = '';
        } else if (fieldName === CallCodesTableEditableColumns.outOfBuildingCallCode) {
          updatedCallCode.outOfBuildingCallCodeIsValid = true;
          updatedCallCode.outOfBuildingCallCodeMessage = '';
        }

        handleOnChangeCode(editingRecordIndex, updatedCallCode);
      }
    },
    [callCodes, editingRecordIndex, handleOnChangeCode]
  );

  const handleOnClickEdit = useCallback(
    (record: IInvalidCode) => {
      const index = callCodes.findIndex((item) => item.flatId === record.flatId);
      if (index !== -1) {
        setEditingRecordIndex(index);
        setEditingRecord(record);
        onEditRow({ ...callCodes[index] });
      }
    },
    [callCodes, onEditRow]
  );

  const resetEdit = useCallback(() => {
    setEditingRecordIndex(null);
    setEditingRecord(null);
    onEditRow(null);
    setClickNumber(0);
  }, [onEditRow]);

  const handleOnSaveEdit = useCallback(async () => {
    if (editingRecordIndex || editingRecordIndex === 0) {
      const updatedCallCode = { ...callCodes[editingRecordIndex] };
      if (
        editingRecord &&
        (updatedCallCode.outOfBuildingCallCode !== editingRecord.outOfBuildingCallCode ||
          updatedCallCode.inBuildingCallCode !== editingRecord.inBuildingCallCode)
      ) {
        const callCode: ICallCode = {
          flatId: updatedCallCode.flatId,
          sectionName: updatedCallCode.sectionName,
          sectionId: updatedCallCode.sectionId,
          flatNumber: updatedCallCode.flatNumber,
          inBuildingCallCode: updatedCallCode.inBuildingCallCode,
          outOfBuildingCallCode: updatedCallCode.outOfBuildingCallCode,
          prefix,
        };
        const resError = await sendRequest(editCallCodeOneFlatUrl(systemId), callCode);
        if (!resError) {
          resetEdit();
          updatedCallCode.inBuildingCallCodeIsValid = true;
          updatedCallCode.inBuildingCallCodeMessage = '';
          updatedCallCode.outOfBuildingCallCodeIsValid = true;
          updatedCallCode.outOfBuildingCallCodeMessage = '';
          return true;
        }

        const errorData = resError.response.data;
        if (errorData.errorCodes === ICallCodeErrorType.notUniqInBuildingCode) {
          updatedCallCode.inBuildingCallCodeIsValid = false;
          updatedCallCode.inBuildingCallCodeMessage = errorData.message;
        } else {
          updatedCallCode.outOfBuildingCallCodeIsValid = false;
          updatedCallCode.outOfBuildingCallCodeMessage = errorData.message;
        }
        handleOnChangeCode(editingRecordIndex, updatedCallCode);
        setClickNumber(clickNumber + 1);
      } else {
        resetEdit();
        return true;
      }
    }
    return false;
  }, [
    callCodes,
    clickNumber,
    editingRecord,
    editingRecordIndex,
    handleOnChangeCode,
    prefix,
    resetEdit,
    sendRequest,
    systemId,
  ]);

  const handleOnClickOutsideEditableRow = useCallback(async () => {
    const isSuccessEdit = await handleOnSaveEdit();
    if (!isSuccessEdit) {
      setNotificationFlatModalIsOpen(!!clickNumber);
    }
  }, [clickNumber, handleOnSaveEdit]);

  const columns = useMemo<ColumnsType<IInvalidCode>>(
    () =>
      [
        {
          title: 'Подъезд',
          dataIndex: 'sectionName',
          key: 'sectionName',
          render: (_: any, record: IInvalidCode) => (
            <div className="editable-table__data-container">{record.sectionName}</div>
          ),
        },
        {
          title: 'Номер квартиры',
          dataIndex: 'flatNumber',
          key: 'flatNumber',
          render: (_: any, record: IInvalidCode) => (
            <div className="editable-table__data-container">{record.flatNumber}</div>
          ),
        },
        {
          title: 'КВ подъезда',
          dataIndex: 'inBuildingCallCode',
          key: 'inBuildingCallCode',
          render: (_: any, record: IInvalidCode) => (
            <div className="editable-table__data-container editable-table__data-container_input">
              <Input
                onClick={() => handleOnClickEdit(record)}
                value={record.inBuildingCallCode?.toString() || ''}
                containerClassName="editable-table__input-container"
                inputClassName="editable-table__input"
                errorTextClassName="editable-table__input-error"
                onChange={(value) =>
                  handleOnChangeEditingRecord(
                    isFiveThousandth ? value : `${toNumber(value) || ''}`,
                    CallCodesTableEditableColumns.inBuildingCallCode
                  )
                }
                maxLength={5}
                status={record.inBuildingCallCodeIsValid ? InputStatus.normal : InputStatus.error}
                errorText={record.inBuildingCallCodeMessage}
                inputType={InputType.numbers}
              />
            </div>
          ),
        },
        {
          title: 'КВ калитки',
          dataIndex: 'outOfBuildingCallCode',
          key: 'outOfBuildingCallCode',
          hidden: !isFiveThousandth,
          render: (_: any, record: IInvalidCode) => (
            <div className="editable-table__data-container editable-table__data-container_input">
              <Input
                onClick={() => handleOnClickEdit(record)}
                value={record.outOfBuildingCallCode?.toString() || ''}
                containerClassName="editable-table__input-container"
                inputClassName="editable-table__input"
                errorTextClassName="editable-table__input-error"
                onChange={(value) =>
                  handleOnChangeEditingRecord(value, CallCodesTableEditableColumns.outOfBuildingCallCode)
                }
                maxLength={5}
                status={record.outOfBuildingCallCodeIsValid ? InputStatus.normal : InputStatus.error}
                errorText={record.outOfBuildingCallCodeMessage}
                inputType={InputType.numbers}
              />
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [handleOnChangeEditingRecord, handleOnClickEdit, isFiveThousandth]
  );

  return (
    <div className="editable-table">
      <NotificationModal
        isOpen={notificationFlatModalIsOpen}
        onCancel={handleOnClickCancelInModal}
        onOk={handleOnClickOkInModal}
        onClose={handleOnClickOkInModal}
      />
      <Table
        columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
        dataSource={callCodes.map((callCode) => ({
          ...callCode,
          key: callCode.flatId,
        }))}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        rowClassName={(_, index) =>
          editRow && index === (editingRecordIndex || 0) - currentPage * pageSize ? 'editable-table__row' : ''
        }
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination',
        }}
      />
      <div
        role="presentation"
        onClick={handleOnClickOutsideEditableRow}
        className={classNames('call-codes-check-modal__row-selected-drop', {
          'call-codes-check-modal__row-selected-drop_visible': !!editingRecordIndex || editingRecordIndex === 0,
        })}
      />
      <div
        role="presentation"
        onClick={handleOnClickOutsideEditableRow}
        className={classNames('editable-table__row-selected-drop', {
          'editable-table__row-selected-drop_visible': !!editingRecordIndex || editingRecordIndex === 0,
        })}
      />
    </div>
  );
};

export default CallCodesCheckTable;
