import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/es/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import lodash, { toNumber } from 'lodash';
import { editCallCode } from '../../../../api/callCode';
import { ICallCodeErrorType } from '../../../../api/callCode/types';
import AddNodeIcon from '../../../../assets/svg/icons/addNote';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import EditIcon from '../../../../assets/svg/icons/edit';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { editCallCodeOneFlatUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { IApiSortField } from '../../../../typings/api';
import { ICallCode } from '../../../../typings/systems/callCode';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Input from '../../../ui/input';
import { InputStatus, InputType } from '../../../ui/input/types';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import NotificationModal from '../../../ui/notificationModal';
import { CallCodesTableEditableColumns, ICallCodesTable } from './types';

const CallCodesTable: FC<ICallCodesTable> = (props) => {
  const {
    callCodes = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    systemId = '',
    onEditRow = () => {},
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    permissions = {},
    isFiveThousandth,
  } = props;

  const { sendRequest } = useApi(editCallCode);
  const [editingRecord, setEditingRecord] = useState<ICallCode | null>(null);
  const [errorCallCodeInBuilding, setErrorCallCodeInBuilding] = useState<string>('');
  const [errorCallCodeOutOfBuilding, setErrorCallCodeOutOfBuilding] = useState<string>('');
  const [clickNumber, setClickNumber] = useState<number>(0);
  const [notificationFlatModalIsOpen, setNotificationFlatModalIsOpen] = useState<boolean>(false);

  const handleOnClickCancelInModal = useCallback(() => {
    setEditingRecord(null);
    setClickNumber(0);
    setErrorCallCodeInBuilding('');
    setErrorCallCodeOutOfBuilding('');
    setNotificationFlatModalIsOpen(false);
  }, []);

  const handleOnClickOkInModal = useCallback(() => {
    setNotificationFlatModalIsOpen(false);
  }, []);

  const isEditing = useCallback((record: ICallCode) => record.flatId === editingRecord?.flatId, [editingRecord]);

  const handleOnChangeEditingRecord = useCallback(
    (value: string, fieldName: CallCodesTableEditableColumns) => {
      const updatedEditingRecord = { ...editingRecord };
      (updatedEditingRecord[fieldName] as any) = value === '' ? null : value;

      if (fieldName === CallCodesTableEditableColumns.inBuildingCallCode) {
        setErrorCallCodeInBuilding('');
      } else if (fieldName === CallCodesTableEditableColumns.outOfBuildingCallCode) {
        setErrorCallCodeOutOfBuilding('');
      }

      setEditingRecord(updatedEditingRecord as ICallCode);
    },
    [editingRecord]
  );

  const handleOnClickEdit = useCallback(
    (record: ICallCode) => {
      if (!editingRecord) {
        setEditingRecord(record);
      }
    },
    [editingRecord]
  );

  const resetEdit = useCallback(() => {
    setEditingRecord(null);
    setClickNumber(0);
    setErrorCallCodeInBuilding('');
    setErrorCallCodeOutOfBuilding('');
  }, []);

  const handleOnSaveEdit = useCallback(async () => {
    if (editingRecord) {
      const flat = callCodes.find((item) => item.flatId === editingRecord.flatId);
      if (flat && !lodash.isEqual({ ...flat, key: editingRecord.key }, editingRecord)) {
        const resError = await sendRequest(editCallCodeOneFlatUrl(systemId), editingRecord);
        if (!resError) {
          onEditRow();
          resetEdit();
          return true;
        }

        const errorData = resError.response.data;
        if (errorData.errorCodes === ICallCodeErrorType.notUniqInBuildingCode) {
          setErrorCallCodeInBuilding(errorData.message);
        } else {
          setErrorCallCodeOutOfBuilding(errorData.message);
        }
        setClickNumber(clickNumber + 1);
      } else {
        resetEdit();
        return true;
      }
    }
    return false;
  }, [callCodes, clickNumber, editingRecord, onEditRow, resetEdit, sendRequest, systemId]);

  const handleOnClickOutsideEditableRow = useCallback(async () => {
    const isSuccessEdit = await handleOnSaveEdit();
    if (!isSuccessEdit) {
      setNotificationFlatModalIsOpen(!!clickNumber);
    }
  }, [clickNumber, handleOnSaveEdit]);

  const handleOnChangeTable: TableProps<ICallCode>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ICallCode> | SorterResult<ICallCode>[],
      extra: TableCurrentDataSource<ICallCode>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<ICallCode>[] = [];

        const addSortItemToSortResult = (item: SorterResult<ICallCode>) => {
          if (item.order) {
            sortResults.push({
              field: item.field as keyof ICallCode,
              descending: item.order === 'descend',
            });
          }
        };

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            addSortItemToSortResult(item);
          });
        } else {
          addSortItemToSortResult(sorter);
        }
        onSort(sortResults);
      }
    },
    [onSort]
  );

  const getColumnSortOrder = useCallback((flatSort: IApiSortField<ICallCode> | null) => {
    if (flatSort) {
      return flatSort.descending ? 'descend' : 'ascend';
    }

    return null;
  }, []);

  const columns = useMemo<ColumnsType<ICallCode>>(
    () =>
      [
        {
          title: 'Подъезд',
          dataIndex: 'sectionName',
          key: 'sectionName',
          sorter: {
            multiple: 2,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'sectionName') || null) as SortOrder,
          render: (_: any, record: ICallCode) => (
            <div className="editable-table__data-container">{record.sectionName}</div>
          ),
        },
        {
          title: 'Код строения',
          dataIndex: 'prefix',
          key: 'prefix',
          render: (_: any, record: ICallCode) => <div className="editable-table__data-container">{record.prefix}</div>,
        },
        {
          title: 'Номер квартиры',
          dataIndex: 'flatNumber',
          key: 'flatNumber',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'flatNumber') || null) as SortOrder,
          render: (_: any, record: ICallCode) => (
            <div className="editable-table__data-container">{record.flatNumber}</div>
          ),
        },
        {
          title: 'КВ подъезда',
          dataIndex: 'inBuildingCallCode',
          key: 'inBuildingCallCode',
          render: (_: any, record: ICallCode) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Input
                  value={editingRecord.inBuildingCallCode?.toString() || ''}
                  containerClassName="editable-table__input-container"
                  inputClassName="editable-table__input"
                  errorTextClassName="editable-table__input-error"
                  onChange={(value) => {
                    handleOnChangeEditingRecord(
                      isFiveThousandth ? value : `${toNumber(value) || ''}`,
                      CallCodesTableEditableColumns.inBuildingCallCode
                    );
                  }}
                  maxLength={5}
                  status={errorCallCodeInBuilding ? InputStatus.error : InputStatus.normal}
                  errorText={errorCallCodeInBuilding}
                  inputType={InputType.numbers}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.inBuildingCallCode || '–'}</div>
            ),
        },
        {
          title: 'КВ калитки',
          dataIndex: 'outOfBuildingCallCode',
          key: 'outOfBuildingCallCode',
          hidden: !isFiveThousandth,
          render: (_: any, record: ICallCode) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Input
                  value={editingRecord.outOfBuildingCallCode?.toString() || ''}
                  containerClassName="editable-table__input-container"
                  inputClassName="editable-table__input"
                  errorTextClassName="editable-table__input-error"
                  onChange={(value) =>
                    handleOnChangeEditingRecord(value, CallCodesTableEditableColumns.outOfBuildingCallCode)
                  }
                  maxLength={5}
                  status={errorCallCodeOutOfBuilding ? InputStatus.error : InputStatus.normal}
                  errorText={errorCallCodeOutOfBuilding}
                  inputType={InputType.numbers}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.outOfBuildingCallCode || '–'}</div>
            ),
        },
        {
          hidden: !permissions.edit,
          render: (record: ICallCode) => (
            <div className="editable-table__icon-container">
              {isEditing(record) ? (
                <div
                  onClick={() => handleOnSaveEdit()}
                  role="presentation"
                  className="editable-table__icon editable-table__icon-active"
                >
                  <EditIcon />
                </div>
              ) : (
                <div onClick={() => handleOnClickEdit(record)} role="presentation" className="editable-table__icon">
                  <EditIcon />
                </div>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [
      editingRecord,
      errorCallCodeInBuilding,
      errorCallCodeOutOfBuilding,
      getColumnSortOrder,
      handleOnChangeEditingRecord,
      handleOnClickEdit,
      handleOnSaveEdit,
      isFiveThousandth,
      isEditing,
      permissions.edit,
      sortOrders,
    ]
  );

  const data = useMemo(
    () =>
      callCodes.map((callCode) => ({
        ...callCode,
        key: callCode.flatId,
      })),
    [callCodes]
  );

  return (
    <div className="editable-table">
      <NotificationModal
        isOpen={notificationFlatModalIsOpen}
        onCancel={handleOnClickCancelInModal}
        onOk={handleOnClickOkInModal}
        onClose={handleOnClickOkInModal}
      />
      <Table
        columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        onChange={handleOnChangeTable}
        showSorterTooltip={false}
        rowClassName={(record) => (record.flatId === editingRecord?.flatId ? 'editable-table__row' : '')}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder
              text={
                <>
                  <span>Добавьте квартиры, чтобы сформировать</span>
                  <span>таблицу с настройками</span>
                </>
              }
              icon={<AddNodeIcon />}
            />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination',
        }}
      />
      <div
        role="presentation"
        onClick={handleOnClickOutsideEditableRow}
        className={classNames('editable-table__row-selected-drop', {
          'editable-table__row-selected-drop_visible': !!editingRecord,
        })}
      />
    </div>
  );
};

export default CallCodesTable;
